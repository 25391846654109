import React, { useState, useEffect } from 'react'

function useFetch(url) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [controller, setController] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        setController(abortController);
        setLoading(true);
        fetch(url, {signal: abortController.signal}).then((response) => response.json()).then((data) => setData(data)).catch((error) => {
            if(error.name == "AbortError"){
                console.log("Request cancelled");
            }else{
                setError(error);
            }
        }).finally(()=>setLoading(false));

        return ()=>abortController.abort();
    }, []);

    const handleCancelRequest = () => {
        if(controller){
            controller.abort();
            setError("Request cancelled");
        }
    }

    return { data, loading, error, handleCancelRequest };
}

export default useFetch