import React from 'react';
import useFetch from '../useFetch';
import "bootstrap/dist/css/bootstrap.min.css";

function ApiUseFetch() {
  const {data, loading, error, handleCancelRequest} = useFetch("https://jsonplaceholder.typicode.com/users");

  return (
    <div>
      <div className='card'>
        <button onClick={handleCancelRequest}>Cancel Request</button>
        <ul>
        {error && <li>Error: {error}</li>}
        {loading && <li>Loading...</li>}
          {data?.map((user)=>(
            <li key={user.id}>{user.name}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ApiUseFetch