import './App.css';
import Navbar from './componenst/Navbar';
//import Aside from './componenst/Aside'
import Footer from "./componenst/Footer";
import Home from './componenst/Home';
import Familia from './componenst/Familia';
import Laboral from './componenst/Laboral';
import Penal from './componenst/Penal';
import Empresarial from './componenst/Empresarial';
import Extranjeria from './componenst/Extranjeria';
import Civil from './componenst/Civil';
import Internacional from './componenst/Internacional';
import Gestor from './componenst/Gestor';
import Notario from './componenst/Notario';
import Custodia from './componenst/Custodia';
import ParejaHecho from './componenst/ParejaHecho';
import Adopcion from './componenst/Adopcion';
import DivorcioEspana from './componenst/DivorcioEspana';
import Acosolaboral from './componenst/Acosolaboral';
import Contratolaboral from './componenst/Contratolaboral';
import Despidoreduccion from './componenst/Despidoreduccion';
import Incapacidadlaboral from './componenst/Incapacidadlaboral';
import Pensiones from './componenst/Pensiones';
import Diferenciaspenal from './componenst/Diferenciaspenal';
import Presuncioninocencia from './componenst/Presuncioninocencia';
import Racismo from './componenst/Racismo';
import Altaautonomo from './componenst/Altaautonomo';
import Sociedadlimitada from './componenst/Sociedadlimitada';
import Licenciarestaurante from './componenst/Licenciarestaurante';
import Licenciaturismo from './componenst/Licenciaturismo';
import Tipoempresa from './componenst/Tipoempresa';
import Zonasoffshore from './componenst/Zonasoffshore';
import Nie from './componenst/Nie';
import Nonlucrativa from './componenst/Nonlucrativa';
import Tiporesidencia from './componenst/Tiporesidencia';
import Visado from './componenst/Visado';
import Derechoautor from './componenst/Derechoautor';
import Difamacion from './componenst/Difamacion';
import Negligenciamedica from './componenst/Negligenciamedica';
import Asilopolitico from './componenst/Asilopolitico';
import Derechohumano from './componenst/Derechohumano';
import Declaracionfiscalautonomo from './componenst/Declaracionfiscalautonomo';
import Irpf from './componenst/Irpf';
import Iva from './componenst/Iva';
import Legalizacionprestamo from './componenst/Legalizacionprestamo';
import Testamento from './componenst/Testamento';
import Stripe from './componenst/Stripe';
import Administracion from './componenst/Administracion';
import Usuario from './componenst/Usuario';
import ApiUseFetch from './componenst/ApiUseFetch';
import ApiFetchData from './componenst/ApiFetchData';

import Error404 from './componenst/Error404';
//import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/familia' element={<Familia/>}/>
        <Route path='/laboral' element={<Laboral/>}/>
        <Route path='/penal' element={<Penal/>}/>
        <Route path='/empresarial' element={<Empresarial/>}/>
        <Route path='/extranjeria' element={<Extranjeria/>}/>
        <Route path='/civil' element={<Civil/>}/>
        <Route path='/internacional' element={<Internacional/>}/>
        <Route path='/gestor' element={<Gestor/>}/>
        <Route path='/notario' element={<Notario/>}/>
        <Route path='/custodia' element={<Custodia/>}/>
        <Route path='/parejahecho' element={<ParejaHecho/>}/>
        <Route path='/adopcion' element={<Adopcion/>}/>
        <Route path='/divorcioespana' element={<DivorcioEspana/>}/>
        <Route path='/acosolaboral' element={<Acosolaboral/>}/>
        <Route path='/contratolaboral' element={<Contratolaboral/>}/>
        <Route path='/despidoreduccion' element={<Despidoreduccion/>}/>
        <Route path='/incapacidadlaboral' element={<Incapacidadlaboral/>}/>
        <Route path='/pensiones' element={<Pensiones/>}/>
        <Route path='/diferenciaspenal' element={<Diferenciaspenal/>}/>
        <Route path='/presuncioninocencia' element={<Presuncioninocencia/>}/>
        <Route path='/racismo' element={<Racismo/>}/>
        <Route path='/altaautonomo' element={<Altaautonomo/>}/>
        <Route path='/sociedadlimitada' element={<Sociedadlimitada/>}/>
        <Route path='/licenciarestaurante' element={<Licenciarestaurante/>}/>
        <Route path='/licenciaturismo' element={<Licenciaturismo/>}/>
        <Route path='/tipoempresa' element={<Tipoempresa/>}/>
        <Route path='/zonasoffshore' element={<Zonasoffshore/>}/>
        <Route path='/nie' element={<Nie/>}/>
        <Route path='/nonlucrativa' element={<Nonlucrativa/>}/>
        <Route path='/tiporesidencia' element={<Tiporesidencia/>}/>
        <Route path='/visado' element={<Visado/>}/>
        <Route path='/derechoautor' element={<Derechoautor/>}/>
        <Route path='/difamacion' element={<Difamacion/>}/>
        <Route path='/negligenciamedica' element={<Negligenciamedica/>}/>
        <Route path='/asilopolitico' element={<Asilopolitico/>}/>
        <Route path='/derechohumano' element={<Derechohumano/>}/>
        <Route path='/declaracionfiscalautonomo' element={<Declaracionfiscalautonomo/>}/>
        <Route path='/irpf' element={<Irpf/>}/>
        <Route path='/iva' element={<Iva/>}/>
        <Route path='/legalizacionprestamo' element={<Legalizacionprestamo/>}/>
        <Route path='/testamento' element={<Testamento/>}/>
        <Route path='/stripe' element={<Stripe/>}/>
        <Route path='/administracion' element={<Administracion/>}/>
        <Route path='/usuario' element={<Usuario/>}/>
        <Route path='/apiusefetch' element={<ApiUseFetch/>}/>
        <Route path='/apifetchdata' element={<ApiFetchData/>}/>
        
        <Route path='*' element={<Error404/>}/>
      </Routes>
      <Footer />
</BrowserRouter>
  );
}

export default App;
