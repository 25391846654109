import axios from "axios";
import React from "react";

//const baseURL = "https://jsonplaceholder.typicode.com/posts/1";
const baseURL = "http://localhost/api-legalallies/usuarios/1";

export default function Usuario() {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.get(baseURL).then((response) => {
      setPost(response.data);
      console.log(response.data);
    });
  }, []);

  if (!post) return null;

  return (
    <div>
      {post.title}
    </div>
  );
}