import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";


import { useTranslation } from "react-i18next";

export default function Administracion() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="main-header">
          <form method="post" action="">
            Usuario: <input name="usuario" type="text"/><br/>
            Contraseña: <input name="contrasena" type="password"/><br/>
            <input type="submit" value="Ingresar"/>
          </form>
        </div>
      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0.5rem;
  .colordiv {
    background-color: #001952;
  }
  .colortext {
    color: white;
  }
`;

const DivBoton = styled.div`
  padding: 0.4rem;
  background: linear-gradient(to bottom right, #001952 60%, pink);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
