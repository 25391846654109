import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/img/proyecto/logo/logo.png";
import "../assets/css/logo.css";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";

export default function Navbar() {
  const [clicked, setCliecked] = useState(false);
  const handleClick = () => {
    setCliecked(!clicked);
  };
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  return (
    <div className="main-header  navbar-white">
      <NavContainer>
        <div className={`links ${clicked ? "active" : ""}`}>
          <nav className="navbar navbar-expand">
            <NavLink to="/">
              <img src={logo} className="csslogo"></img>
            </NavLink>
          <div className="navbar-nav ml-auto">
            <button
                name={"es"}
                value={"es"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco espanna"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto1")}
              </button>
              <button
                name={"en"}
                value={"en"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco ingles"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto3")}
              </button>
              <button
                name={"ma"}
                value={"ma"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco marruecos"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto4")}
              </button>
              <button
                name={"ru"}
                value={"ru"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco rusia"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto5")}
              </button>
              <button
                name={"in"}
                value={"in"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco india"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto6")}
              </button>
              <button
                name={"pk"}
                value={"pk"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco pakistan"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto7")}
              </button>
              <button
                name={"am"}
                value={"am"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco armenio"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto10")}
              </button>
              <button
                name={"ge"}
                value={"ge"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco georgia"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto11")}
              </button>
              <button
                name={"ro"}
                value={"ro"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco rumania"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto9")}
              </button>
              <button
                name={"ua"}
                value={"ua"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco ucrania"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto8")}
              </button>
              <button
                name={"cn"}
                value={"cn"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco china"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto12")}
              </button>
              <button
                name={"inb"}
                value={"inb"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco india"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto13")}
              </button>
              <button
                name={"pt"}
                value={"pt"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco portugal"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto14")}
              </button>
              <button
                name={"al"}
                value={"al"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco albania"
                type="submit"
              >
                  {t("siglas.texto15")}
              </button>
    
          </div>
        </nav>

        </div>
        <div className="burguer">
          <div>
          <button
                name={"es"}
                value={"es"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco espanna"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto1")}
              </button>
              <button
                name={"en"}
                value={"en"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco ingles"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto3")}
              </button>
              <button
                name={"ma"}
                value={"ma"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco marruecos"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto4")}
              </button>
              <button
                name={"ru"}
                value={"ru"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco rusia"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto5")}
              </button>
              <button
                name={"in"}
                value={"in"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco india"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto6")}
              </button>
              <button
                name={"pk"}
                value={"pk"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco pakistan"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto7")}
              </button>
              <button
                name={"am"}
                value={"am"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco armenio"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto10")}
              </button>
              <button
                name={"ge"}
                value={"ge"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco georgia"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto11")}
              </button>
              <button
                name={"ro"}
                value={"ro"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco rumania"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto9")}
              </button>
              <button
                name={"ua"}
                value={"ua"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco ucrania"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto8")}
              </button>
              <button
                name={"cn"}
                value={"cn"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco china"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto12")}
              </button>
              <button
                name={"inb"}
                value={"inb"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco india"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto13")}
              </button>
              <button
                name={"pt"}
                value={"pt"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco portugal"
                style={{marginRight: 5}}
                type="submit"
              >
                  {t("siglas.texto14")}
              </button>
              <button
                name={"al"}
                value={"al"}
                onClick={handleChangeLanguage}
                className=" btn_transparent btn color-blanco albania"
                type="submit"
              >
                  {t("siglas.texto15")}
              </button>
          </div>
        </div>
        <BgDiv className={`initial ${clicked ? "active" : ""}`}></BgDiv>
      </NavContainer>
    </div>
  );
}

const NavContainer = styled.nav`
button {
    border: none;
}

.boton-transparente {
  display: inline-block;
  width: 200px;
  padding: 8px;
  color: #fff;
  border: 2px solid #fff;
  text-align: center;
  outline: none;
  text-decoration: none;
  transition: background-color 0.2s ease-out,
              color 0.2s ease-out;
}
.color-blanco{
  color: #FFFFFF;
}
.boton-transparente:hover,
.boton-transparente:active {
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s ease-in,
              color 0.3s ease-in;
}
                  
h2{
    color:  #d4ac0d ;
    font-weight: 400;
    span{
        font-weight:bold;
        color: white;
    }
    padding: .6rem;
}

h3{
  color:  #d4ac0d ;
  font-weight: 400;
  span{
      font-weight:bold;
      color: white;
  }
  padding: .6rem;
}

img{
    font-weight: 400;
    span{
        font-weight:bold;
    }
    padding: .6rem;
}
padding: .4rem;
background: linear-gradient(to bottom right, #001952 60%, pink);
align-items: center;
justify-content: space-between;
a{
    color: while;
    text-decoration: none;
    margin-right: 2rem;
}
.links{
    position:absolute;
    top: -700px;
    left: -2000px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: all .5s ease;
    a{
        color: white;
        font-size: 2rem;
        display: block;
    }
    @media(min-width: 768px){
        position: initial;
        margin: 0;
        a{
            font-size: 1rem;
            color: white;
            display: inline;
        }
    }
}
.links.active{
    width: 100%
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
    a{
        font-size: rem;
        margin-top: 1rem;
        color: white;
    }
}
.burguer{
    a{
        color: white;
        font-size: 1rem;
        display: block;
        margin: 10px 10px;
        position: relative;
        cursor: pointer;
        display: inline-block;
    }
    @media(min-width: 768px){
        display: none;
    }
}
`;

const BgDiv = styled.div`
  background-color: #222;
  position: absolute;
  top: -700px;
  left: -1000px;
  width: 100%;
  height: 100%;
  z-index: -1;
  &.active {
    border-radius: 0 0 80% 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;


//🇩🇪🇫🇷🇪🇸🇪🇺🇬🇧🇺🇸🇯🇵


/*

[
  {
    "countryCode": "AF",
    "country": "Afghanistan",
    "flag": "🇦🇫"
  },
  {
    "countryCode": "AX",
    "country": "Åland Islands",
    "flag": "🇦🇽"
  },
  {
    "countryCode": "AL",
    "country": "Albania",
    "flag": "🇦🇱"
  },
  {
    "countryCode": "DZ",
    "country": "Algeria",
    "flag": "🇩🇿"
  },
  {
    "countryCode": "AS",
    "country": "American Samoa",
    "flag": "🇦🇸"
  },
  {
    "countryCode": "AD",
    "country": "Andorra",
    "flag": "🇦🇩"
  },
  {
    "countryCode": "AO",
    "country": "Angola",
    "flag": "🇦🇴"
  },
  {
    "countryCode": "AI",
    "country": "Anguilla",
    "flag": "🇦🇮"
  },
  {
    "countryCode": "AQ",
    "country": "Antarctica",
    "flag": "🇦🇶"
  },
  {
    "countryCode": "AG",
    "country": "Antigua and Barbuda",
    "flag": "🇦🇬"
  },
  {
    "countryCode": "AR",
    "country": "Argentina",
    "flag": "🇦🇷"
  },
  {
    "countryCode": "AM",
    "country": "Armenia",
    "flag": "🇦🇲"
  },
  {
    "countryCode": "AW",
    "country": "Aruba",
    "flag": "🇦🇼"
  },
  {
    "countryCode": "AU",
    "country": "Australia",
    "flag": "🇦🇺"
  },
  {
    "countryCode": "AT",
    "country": "Austria",
    "flag": "🇦🇹"
  },
  {
    "countryCode": "AZ",
    "country": "Azerbaijan",
    "flag": "🇦🇿"
  },
  {
    "countryCode": "BS",
    "country": "Bahamas",
    "flag": "🇧🇸"
  },
  {
    "countryCode": "BH",
    "country": "Bahrain",
    "flag": "🇧🇭"
  },
  {
    "countryCode": "BD",
    "country": "Bangladesh",
    "flag": "🇧🇩"
  },
  {
    "countryCode": "BB",
    "country": "Barbados",
    "flag": "🇧🇧"
  },
  {
    "countryCode": "BY",
    "country": "Belarus",
    "flag": "🇧🇾"
  },
  {
    "countryCode": "BE",
    "country": "Belgium",
    "flag": "🇧🇪"
  },
  {
    "countryCode": "BZ",
    "country": "Belize",
    "flag": "🇧🇿"
  },
  {
    "countryCode": "BJ",
    "country": "Benin",
    "flag": "🇧🇯"
  },
  {
    "countryCode": "BM",
    "country": "Bermuda",
    "flag": "🇧🇲"
  },
  {
    "countryCode": "BT",
    "country": "Bhutan",
    "flag": "🇧🇹"
  },
  {
    "countryCode": "BO",
    "country": "Bolivia, Plurinational State of",
    "flag": "🇧🇴"
  },
  {
    "countryCode": "BA",
    "country": "Bosnia and Herzegovina",
    "flag": "🇧🇦"
  },
  {
    "countryCode": "BW",
    "country": "Botswana",
    "flag": "🇧🇼"
  },
  {
    "countryCode": "BV",
    "country": "Bouvet Island",
    "flag": "🇧🇻"
  },
  {
    "countryCode": "BR",
    "country": "Brazil",
    "flag": "🇧🇷"
  },
  {
    "countryCode": "IO",
    "country": "British Indian Ocean Territory",
    "flag": "🇮🇴"
  },
  {
    "countryCode": "BN",
    "country": "Brunei Darussalam",
    "flag": "🇧🇳"
  },
  {
    "countryCode": "BG",
    "country": "Bulgaria",
    "flag": "🇧🇬"
  },
  {
    "countryCode": "BF",
    "country": "Burkina Faso",
    "flag": "🇧🇫"
  },
  {
    "countryCode": "BI",
    "country": "Burundi",
    "flag": "🇧🇮"
  },
  {
    "countryCode": "KH",
    "country": "Cambodia",
    "flag": "🇰🇭"
  },
  {
    "countryCode": "CM",
    "country": "Cameroon",
    "flag": "🇨🇲"
  },
  {
    "countryCode": "CA",
    "country": "Canada",
    "flag": "🇨🇦"
  },
  {
    "countryCode": "CV",
    "country": "Cabo Verde",
    "flag": "🇨🇻"
  },
  {
    "countryCode": "KY",
    "country": "Cayman Islands",
    "flag": "🇰🇾"
  },
  {
    "countryCode": "CF",
    "country": "Central African Republic",
    "flag": "🇨🇫"
  },
  {
    "countryCode": "TD",
    "country": "Chad",
    "flag": "🇹🇩"
  },
  {
    "countryCode": "CL",
    "country": "Chile",
    "flag": "🇨🇱"
  },
  {
    "countryCode": "CN",
    "country": "China",
    "flag": "🇨🇳"
  },
  {
    "countryCode": "CX",
    "country": "Christmas Island",
    "flag": "🇨🇽"
  },
  {
    "countryCode": "CC",
    "country": "Cocos (Keeling) Islands",
    "flag": "🇨🇨"
  },
  {
    "countryCode": "CO",
    "country": "Colombia",
    "flag": "🇨🇴"
  },
  {
    "countryCode": "KM",
    "country": "Comoros",
    "flag": "🇰🇲"
  },
  {
    "countryCode": "CG",
    "country": "Congo",
    "flag": "🇨🇬"
  },
  {
    "countryCode": "CD",
    "country": "Congo, The Democratic Republic of the",
    "flag": "🇨🇩"
  },
  {
    "countryCode": "CK",
    "country": "Cook Islands",
    "flag": "🇨🇰"
  },
  {
    "countryCode": "CR",
    "country": "Costa Rica",
    "flag": "🇨🇷"
  },
  {
    "countryCode": "CI",
    "country": "Côte d'Ivoire",
    "flag": "🇨🇮"
  },
  {
    "countryCode": "HR",
    "country": "Croatia",
    "flag": "🇭🇷"
  },
  {
    "countryCode": "CU",
    "country": "Cuba",
    "flag": "🇨🇺"
  },
  {
    "countryCode": "CY",
    "country": "Cyprus",
    "flag": "🇨🇾"
  },
  {
    "countryCode": "CZ",
    "country": "Czechia",
    "flag": "🇨🇿"
  },
  {
    "countryCode": "DK",
    "country": "Denmark",
    "flag": "🇩🇰"
  },
  {
    "countryCode": "DJ",
    "country": "Djibouti",
    "flag": "🇩🇯"
  },
  {
    "countryCode": "DM",
    "country": "Dominica",
    "flag": "🇩🇲"
  },
  {
    "countryCode": "DO",
    "country": "Dominican Republic",
    "flag": "🇩🇴"
  },
  {
    "countryCode": "EC",
    "country": "Ecuador",
    "flag": "🇪🇨"
  },
  {
    "countryCode": "EG",
    "country": "Egypt",
    "flag": "🇪🇬"
  },
  {
    "countryCode": "SV",
    "country": "El Salvador",
    "flag": "🇸🇻"
  },
  {
    "countryCode": "GQ",
    "country": "Equatorial Guinea",
    "flag": "🇬🇶"
  },
  {
    "countryCode": "ER",
    "country": "Eritrea",
    "flag": "🇪🇷"
  },
  {
    "countryCode": "EE",
    "country": "Estonia",
    "flag": "🇪🇪"
  },
  {
    "countryCode": "SZ",
    "country": "Eswatini",
    "flag": "🇸🇿"
  },
  {
    "countryCode": "ET",
    "country": "Ethiopia",
    "flag": "🇪🇹"
  },
  {
    "countryCode": "FK",
    "country": "Falkland Islands (Malvinas)",
    "flag": "🇫🇰"
  },
  {
    "countryCode": "FO",
    "country": "Faroe Islands",
    "flag": "🇫🇴"
  },
  {
    "countryCode": "FJ",
    "country": "Fiji",
    "flag": "🇫🇯"
  },
  {
    "countryCode": "FI",
    "country": "Finland",
    "flag": "🇫🇮"
  },
  {
    "countryCode": "FR",
    "country": "France",
    "flag": "🇫🇷"
  },
  {
    "countryCode": "GF",
    "country": "French Guiana",
    "flag": "🇬🇫"
  },
  {
    "countryCode": "PF",
    "country": "French Polynesia",
    "flag": "🇵🇫"
  },
  {
    "countryCode": "TF",
    "country": "French Southern Territories",
    "flag": "🇹🇫"
  },
  {
    "countryCode": "GA",
    "country": "Gabon",
    "flag": "🇬🇦"
  },
  {
    "countryCode": "GM",
    "country": "Gambia",
    "flag": "🇬🇲"
  },
  {
    "countryCode": "GE",
    "country": "Georgia",
    "flag": "🇬🇪"
  },
  {
    "countryCode": "DE",
    "country": "Germany",
    "flag": "🇩🇪"
  },
  {
    "countryCode": "GH",
    "country": "Ghana",
    "flag": "🇬🇭"
  },
  {
    "countryCode": "GI",
    "country": "Gibraltar",
    "flag": "🇬🇮"
  },
  {
    "countryCode": "GR",
    "country": "Greece",
    "flag": "🇬🇷"
  },
  {
    "countryCode": "GL",
    "country": "Greenland",
    "flag": "🇬🇱"
  },
  {
    "countryCode": "GD",
    "country": "Grenada",
    "flag": "🇬🇩"
  },
  {
    "countryCode": "GP",
    "country": "Guadeloupe",
    "flag": "🇬🇵"
  },
  {
    "countryCode": "GU",
    "country": "Guam",
    "flag": "🇬🇺"
  },
  {
    "countryCode": "GT",
    "country": "Guatemala",
    "flag": "🇬🇹"
  },
  {
    "countryCode": "GG",
    "country": "Guernsey",
    "flag": "🇬🇬"
  },
  {
    "countryCode": "GN",
    "country": "Guinea",
    "flag": "🇬🇳"
  },
  {
    "countryCode": "GW",
    "country": "Guinea-Bissau",
    "flag": "🇬🇼"
  },
  {
    "countryCode": "GY",
    "country": "Guyana",
    "flag": "🇬🇾"
  },
  {
    "countryCode": "HT",
    "country": "Haiti",
    "flag": "🇭🇹"
  },
  {
    "countryCode": "HM",
    "country": "Heard Island and McDonald Islands",
    "flag": "🇭🇲"
  },
  {
    "countryCode": "VA",
    "country": "Holy See (Vatican City State)",
    "flag": "🇻🇦"
  },
  {
    "countryCode": "HN",
    "country": "Honduras",
    "flag": "🇭🇳"
  },
  {
    "countryCode": "HK",
    "country": "Hong Kong",
    "flag": "🇭🇰"
  },
  {
    "countryCode": "HU",
    "country": "Hungary",
    "flag": "🇭🇺"
  },
  {
    "countryCode": "IS",
    "country": "Iceland",
    "flag": "🇮🇸"
  },
  {
    "countryCode": "IN",
    "country": "India", 
    "flag": "🇮🇳"
  },
  {
    "countryCode": "ID",
    "country": "Indonesia",
    "flag": "🇮🇩"
  },
  {
    "countryCode": "IR",
    "country": "Iran, Islamic Republic of",
    "flag": "🇮🇷"
  },
  {
    "countryCode": "IQ",
    "country": "Iraq",
    "flag": "🇮🇶"
  },
  {
    "countryCode": "IE",
    "country": "Ireland",
    "flag": "🇮🇪"
  },
  {
    "countryCode": "IM",
    "country": "Isle of Man",
    "flag": "🇮🇲"
  },
  {
    "countryCode": "IL",
    "country": "Israel",
    "flag": "🇮🇱"
  },
  {
    "countryCode": "IT",
    "country": "Italy",
    "flag": "🇮🇹"
  },
  {
    "countryCode": "JM",
    "country": "Jamaica",
    "flag": "🇯🇲"
  },
  {
    "countryCode": "JP",
    "country": "Japan",
    "flag": "🇯🇵"
  },
  {
    "countryCode": "JE",
    "country": "Jersey",
    "flag": "🇯🇪"
  },
  {
    "countryCode": "JO",
    "country": "Jordan",
    "flag": "🇯🇴"
  },
  {
    "countryCode": "KZ",
    "country": "Kazakhstan",
    "flag": "🇰🇿"
  },
  {
    "countryCode": "KE",
    "country": "Kenya",
    "flag": "🇰🇪"
  },
  {
    "countryCode": "KI",
    "country": "Kiribati",
    "flag": "🇰🇮"
  },
  {
    "countryCode": "KP",
    "country": "Korea, Democratic People's Republic of",
    "flag": "🇰🇵"
  },
  {
    "countryCode": "KR",
    "country": "Korea, Republic of",
    "flag": "🇰🇷"
  },
  {
    "countryCode": "KW",
    "country": "Kuwait",
    "flag": "🇰🇼"
  },
  {
    "countryCode": "KG",
    "country": "Kyrgyzstan",
    "flag": "🇰🇬"
  },
  {
    "countryCode": "LA",
    "country": "Lao People's Democratic Republic",
    "flag": "🇱🇦"
  },
  {
    "countryCode": "LV",
    "country": "Latvia",
    "flag": "🇱🇻"
  },
  {
    "countryCode": "LB",
    "country": "Lebanon",
    "flag": "🇱🇧"
  },
  {
    "countryCode": "LS",
    "country": "Lesotho",
    "flag": "🇱🇸"
  },
  {
    "countryCode": "LR",
    "country": "Liberia",
    "flag": "🇱🇷"
  },
  {
    "countryCode": "LY",
    "country": "Libya",
    "flag": "🇱🇾"
  },
  {
    "countryCode": "LI",
    "country": "Liechtenstein",
    "flag": "🇱🇮"
  },
  {
    "countryCode": "LT",
    "country": "Lithuania",
    "flag": "🇱🇹"
  },
  {
    "countryCode": "LU",
    "country": "Luxembourg",
    "flag": "🇱🇺"
  },
  {
    "countryCode": "MO",
    "country": "Macao",
    "flag": "🇲🇴"
  },
  {
    "countryCode": "MG",
    "country": "Madagascar",
    "flag": "🇲🇬"
  },
  {
    "countryCode": "MW",
    "country": "Malawi",
    "flag": "🇲🇼"
  },
  {
    "countryCode": "MY",
    "country": "Malaysia",
    "flag": "🇲🇾"
  },
  {
    "countryCode": "MV",
    "country": "Maldives",
    "flag": "🇲🇻"
  },
  {
    "countryCode": "ML",
    "country": "Mali",
    "flag": "🇲🇱"
  },
  {
    "countryCode": "MT",
    "country": "Malta",
    "flag": "🇲🇹"
  },
  {
    "countryCode": "MH",
    "country": "Marshall Islands",
    "flag": "🇲🇭"
  },
  {
    "countryCode": "MQ",
    "country": "Martinique",
    "flag": "🇲🇶"
  },
  {
    "countryCode": "MR",
    "country": "Mauritania",
    "flag": "🇲🇷"
  },
  {
    "countryCode": "MU",
    "country": "Mauritius",
    "flag": "🇲🇺"
  },
  {
    "countryCode": "YT",
    "country": "Mayotte",
    "flag": "🇾🇹"
  },
  {
    "countryCode": "MX",
    "country": "Mexico",
    "flag": "🇲🇽"
  },
  {
    "countryCode": "FM",
    "country": "Micronesia, Federated States of",
    "flag": "🇫🇲"
  },
  {
    "countryCode": "MD",
    "country": "Moldova, Republic of",
    "flag": "🇲🇩"
  },
  {
    "countryCode": "MC",
    "country": "Monaco",
    "flag": "🇲🇨"
  },
  {
    "countryCode": "MN",
    "country": "Mongolia",
    "flag": "🇲🇳"
  },
  {
    "countryCode": "ME",
    "country": "Montenegro",
    "flag": "🇲🇪"
  },
  {
    "countryCode": "MS",
    "country": "Montserrat",
    "flag": "🇲🇸"
  },
  {
    "countryCode": "MA",
    "country": "Morocco",
    "flag": "🇲🇦"
  },
  {
    "countryCode": "MZ",
    "country": "Mozambique",
    "flag": "🇲🇿"
  },
  {
    "countryCode": "MM",
    "country": "Myanmar",
    "flag": "🇲🇲"
  },
  {
    "countryCode": "NA",
    "country": "Namibia",
    "flag": "🇳🇦"
  },
  {
    "countryCode": "NR",
    "country": "Nauru",
    "flag": "🇳🇷"
  },
  {
    "countryCode": "NP",
    "country": "Nepal",
    "flag": "🇳🇵"
  },
  {
    "countryCode": "NL",
    "country": "Netherlands, Kingdom of the",
    "flag": "🇳🇱"
  },
  {
    "countryCode": "AN",
    "country": "Netherlands Antilles",
    "flag": "🇳🇱"
  },
  {
    "countryCode": "NC",
    "country": "New Caledonia",
    "flag": "🇳🇨"
  },
  {
    "countryCode": "NZ",
    "country": "New Zealand",
    "flag": "🇳🇿"
  },
  {
    "countryCode": "NI",
    "country": "Nicaragua",
    "flag": "🇳🇮"
  },
  {
    "countryCode": "NE",
    "country": "Niger",
    "flag": "🇳🇪"
  },
  {
    "countryCode": "NG",
    "country": "Nigeria",
    "flag": "🇳🇬"
  },
  {
    "countryCode": "NU",
    "country": "Niue",
    "flag": "🇳🇺"
  },
  {
    "countryCode": "NF",
    "country": "Norfolk Island",
    "flag": "🇳🇫"
  },
  {
    "countryCode": "MP",
    "country": "Northern Mariana Islands",
    "flag": "🇲🇵"
  },
  {
    "countryCode": "MK",
    "country": "North Macedonia",
    "flag": "🇲🇰"
  },
  {
    "countryCode": "NO",
    "country": "Norway",
    "flag": "🇳🇴"
  },
  {
    "countryCode": "OM",
    "country": "Oman",
    "flag": "🇴🇲"
  },
  {
    "countryCode": "PK",
    "country": "Pakistan",
    "flag": "🇵🇰"
  },
  {
    "countryCode": "PW",
    "country": "Palau",
    "flag": "🇵🇼"
  },
  {
    "countryCode": "PS",
    "country": "Palestine, State of",
    "flag": "🇵🇸"
  },
  {
    "countryCode": "PA",
    "country": "Panama",
    "flag": "🇵🇦"
  },
  {
    "countryCode": "PG",
    "country": "Papua New Guinea",
    "flag": "🇵🇬"
  },
  {
    "countryCode": "PY",
    "country": "Paraguay",
    "flag": "🇵🇾"
  },
  {
    "countryCode": "PE",
    "country": "Peru",
    "flag": "🇵🇪"
  },
  {
    "countryCode": "PH",
    "country": "Philippines",
    "flag": "🇵🇭"
  },
  {
    "countryCode": "PN",
    "country": "Pitcairn",
    "flag": "🇵🇳"
  },
  {
    "countryCode": "PL",
    "country": "Poland",
    "flag": "🇵🇱"
  },
  {
    "countryCode": "PT",
    "country": "Portugal",
    "flag": "🇵🇹"
  },
  {
    "countryCode": "PR",
    "country": "Puerto Rico",
    "flag": "🇵🇷"
  },
  {
    "countryCode": "QA",
    "country": "Qatar",
    "flag": "🇶🇦"
  },
  {
    "countryCode": "RE",
    "country": "Réunion",
    "flag": "🇷🇪"
  },
  {
    "countryCode": "RO",
    "country": "Romania",
    "flag": "🇷🇴"
  },
  {
    "countryCode": "RU",
    "country": "Russian Federation",
    "flag": "🇷🇺"
  },
  {
    "countryCode": "RW",
    "country": "Rwanda",
    "flag": "🇷🇼"
  },
  {
    "countryCode": "BL",
    "country": "Saint Barthélemy",
    "flag": "🇧🇱"
  },
  {
    "countryCode": "SH",
    "country": "Saint Helena, Ascension and Tristan da Cunha",
    "flag": "🇸🇭"
  },
  {
    "countryCode": "KN",
    "country": "Saint Kitts and Nevis",
    "flag": "🇰🇳"
  },
  {
    "countryCode": "LC",
    "country": "Saint Lucia",
    "flag": "🇱🇨"
  },
  {
    "countryCode": "MF",
    "country": "Saint Martin",
    "flag": "🇲🇫"
  },
  {
    "countryCode": "PM",
    "country": "Saint Pierre and Miquelon",
    "flag": "🇵🇲"
  },
  {
    "countryCode": "VC",
    "country": "Saint Vincent and the Grenadines",
    "flag": "🇻🇨"
  },
  {
    "countryCode": "WS",
    "country": "Samoa",
    "flag": "🇼🇸"
  },
  {
    "countryCode": "SM",
    "country": "San Marino",
    "flag": "🇸🇲"
  },
  {
    "countryCode": "ST",
    "country": "Sao Tome and Principe",
    "flag": "🇸🇹"
  },
  {
    "countryCode": "SA",
    "country": "Saudi Arabia",
    "flag": "🇸🇦"
  },
  {
    "countryCode": "SN",
    "country": "Senegal",
    "flag": "🇸🇳"
  },
  {
    "countryCode": "RS",
    "country": "Serbia",
    "flag": "🇷🇸"
  },
  {
    "countryCode": "SC",
    "country": "Seychelles",
    "flag": "🇸🇨"
  },
  {
    "countryCode": "SL",
    "country": "Sierra Leone",
    "flag": "🇸🇱"
  },
  {
    "countryCode": "SG",
    "country": "Singapore",
    "flag": "🇸🇬"
  },
  {
    "countryCode": "SK",
    "country": "Slovakia",
    "flag": "🇸🇰"
  },
  {
    "countryCode": "SI",
    "country": "Slovenia",
    "flag": "🇸🇮"
  },
  {
    "countryCode": "SB",
    "country": "Solomon Islands",
    "flag": "🇸🇧"
  },
  {
    "countryCode": "SO",
    "country": "Somalia",
    "flag": "🇸🇴"
  },
  {
    "countryCode": "ZA",
    "country": "South Africa",
    "flag": "🇿🇦"
  },
  {
    "countryCode": "GS",
    "country": "South Georgia and the South Sandwich Islands",
    "flag": "🇬🇸"
  },
  {
    "countryCode": "ES",
    "country": "Spain",
    "flag": "🇪🇸"
  },
  {
    "countryCode": "LK",
    "country": "Sri Lanka",
    "flag": "🇱🇰"
  },
  {
    "countryCode": "SD",
    "country": "Sudan",
    "flag": "🇸🇩"
  },
  {
    "countryCode": "SR",
    "country": "Suriname",
    "flag": "🇸🇷"
  },
  {
    "countryCode": "SJ",
    "country": "Svalbard and Jan Mayen",
    "flag": "🇸🇯"
  },
  {
    "countryCode": "SE",
    "country": "Sweden",
    "flag": "🇸🇪"
  },
  {
    "countryCode": "CH",
    "country": "Switzerland",
    "flag": "🇨🇭"
  },
  {
    "countryCode": "SY",
    "country": "Syrian Arab Republic",
    "flag": "🇸🇾"
  },
  {
    "countryCode": "TW",
    "country": "Taiwan, Province of China",
    "flag": "🇹🇼"
  },
  {
    "countryCode": "TJ",
    "country": "Tajikistan",
    "flag": "🇹🇯"
  },
  {
    "countryCode": "TZ",
    "country": "Tanzania, United Republic of",
    "flag": "🇹🇿"
  },
  {
    "countryCode": "TH",
    "country": "Thailand",
    "flag": "🇹🇭"
  },
  {
    "countryCode": "TL",
    "country": "Timor-Leste",
    "flag": "🇹🇱"
  },
  {
    "countryCode": "TG",
    "country": "Togo",
    "flag": "🇹🇬"
  },
  {
    "countryCode": "TK",
    "country": "Tokelau",
    "flag": "🇹🇰"
  },
  {
    "countryCode": "TO",
    "country": "Tonga",
    "flag": "🇹🇴"
  },
  {
    "countryCode": "TT",
    "country": "Trinidad and Tobago",
    "flag": "🇹🇹"
  },
  {
    "countryCode": "TN",
    "country": "Tunisia",
    "flag": "🇹🇳"
  },
  {
    "countryCode": "TM",
    "country": "Turkmenistan",
    "flag": "🇹🇲"
  },
  {
    "countryCode": "TC",
    "country": "Turks and Caicos Islands",
    "flag": "🇹🇨"
  },
  {
    "countryCode": "TV",
    "country": "Tuvalu",
    "flag": "🇹🇻"
  },
  {
    "countryCode": "TR",
    "country": "Türkiye",
    "flag": "🇹🇷"
  },
  {
    "countryCode": "UG",
    "country": "Uganda",
    "flag": "🇺🇬"
  },
  {
    "countryCode": "UA",
    "country": "Ukraine",
    "flag": "🇺🇦"
  },
  {
    "countryCode": "AE",
    "country": "United Arab Emirates",
    "flag": "🇦🇪"
  },
  {
    "countryCode": "GB",
    "country": "United Kingdom",
    "flag": "🇬🇧"
  },
  {
    "countryCode": "US",
    "country": "United States of America",
    "flag": "🇺🇸"
  },
  {
    "countryCode": "UM",
    "country": "United States Minor Outlying Islands",
    "flag": "🇺🇸"
  },
  {
    "countryCode": "UY",
    "country": "Uruguay",
    "flag": "🇺🇾"
  },
  {
    "countryCode": "UZ",
    "country": "Uzbekistan",
    "flag": "🇺🇿"
  },
  {
    "countryCode": "VU",
    "country": "Vanuatu",
    "flag": "🇻🇺"
  },
  {
    "countryCode": "VE",
    "country": "Venezuela, Bolivarian Republic of",
    "flag": "🇻🇪"
  },
  {
    "countryCode": "VN",
    "country": "Viet Nam",
    "flag": "🇻🇳"
  },
  {
    "countryCode": "VG",
    "country": "Virgin Islands, British",
    "flag": "🇻🇬"
  },
  {
    "countryCode": "VI",
    "country": "Virgin Islands, U.S.",
    "flag": "🇻🇮"
  },
  {
    "countryCode": "WF",
    "country": "Wallis and Futuna",
    "flag": "🇼🇫"
  },
  {
    "countryCode": "EH",
    "country": "Western Sahara",
    "flag": "🇪🇭"
  },
  {
    "countryCode": "YE",
    "country": "Yemen",
    "flag": "🇾🇪"
  },
  {
    "countryCode": "ZM",
    "country": "Zambia",
    "flag": "🇿🇲"
  },
  {
    "countryCode": "ZW",
    "country": "Zimbabwe",
    "flag": "🇿🇼"
  }
]



           

<select name="country">
  <option value="AF">Afghanistan 🇦🇫</option>
  <option value="AX">Åland Islands 🇦🇽</option>
  <option value="AL">Albania 🇦🇱</option>
  <option value="DZ">Algeria 🇩🇿</option>
  <option value="AS">American Samoa 🇦🇸</option>
  <option value="AD">Andorra 🇦🇩</option>
  <option value="AO">Angola 🇦🇴</option>
  <option value="AI">Anguilla 🇦🇮</option>
  <option value="AQ">Antarctica 🇦🇶</option>
  <option value="AG">Antigua and Barbuda 🇦🇬</option>
  <option value="AR">Argentina 🇦🇷</option>
  <option value="AM">Armenia 🇦🇲</option>
  <option value="AW">Aruba 🇦🇼</option>
  <option value="AU">Australia 🇦🇺</option>
  <option value="AT">Austria 🇦🇹</option>
  <option value="AZ">Azerbaijan 🇦🇿</option>
  <option value="BS">Bahamas 🇧🇸</option>
  <option value="BH">Bahrain 🇧🇭</option>
  <option value="BD">Bangladesh 🇧🇩</option>
  <option value="BB">Barbados 🇧🇧</option>
  <option value="BY">Belarus 🇧🇾</option>
  <option value="BE">Belgium 🇧🇪</option>
  <option value="BZ">Belize 🇧🇿</option>
  <option value="BJ">Benin 🇧🇯</option>
  <option value="BM">Bermuda 🇧🇲</option>
  <option value="BT">Bhutan 🇧🇹</option>
  <option value="BO">Bolivia, Plurinational State of 🇧🇴</option>
  <option value="BA">Bosnia and Herzegovina 🇧🇦</option>
  <option value="BW">Botswana 🇧🇼</option>
  <option value="BV">Bouvet Island 🇧🇻</option>
  <option value="BR">Brazil 🇧🇷</option>
  <option value="IO">British Indian Ocean Territory 🇮🇴</option>
  <option value="BN">Brunei Darussalam 🇧🇳</option>
  <option value="BG">Bulgaria 🇧🇬</option>
  <option value="BF">Burkina Faso 🇧🇫</option>
  <option value="BI">Burundi 🇧🇮</option>
  <option value="KH">Cambodia 🇰🇭</option>
  <option value="CM">Cameroon 🇨🇲</option>
  <option value="CA">Canada 🇨🇦</option>
  <option value="CV">Cabo Verde 🇨🇻</option>
  <option value="KY">Cayman Islands 🇰🇾</option>
  <option value="CF">Central African Republic 🇨🇫</option>
  <option value="TD">Chad 🇹🇩</option>
  <option value="CL">Chile 🇨🇱</option>
  <option value="CN">China 🇨🇳</option>
  <option value="CX">Christmas Island 🇨🇽</option>
  <option value="CC">Cocos (Keeling) Islands 🇨🇨</option>
  <option value="CO">Colombia 🇨🇴</option>
  <option value="KM">Comoros 🇰🇲</option>
  <option value="CG">Congo 🇨🇬</option>
  <option value="CD">Congo, The Democratic Republic of the 🇨🇩</option>
  <option value="CK">Cook Islands 🇨🇰</option>
  <option value="CR">Costa Rica 🇨🇷</option>
  <option value="CI">Côte d'Ivoire 🇨🇮</option>
  <option value="HR">Croatia 🇭🇷</option>
  <option value="CU">Cuba 🇨🇺</option>
  <option value="CY">Cyprus 🇨🇾</option>
  <option value="CZ">Czechia 🇨🇿</option>
  <option value="DK">Denmark 🇩🇰</option>
  <option value="DJ">Djibouti 🇩🇯</option>
  <option value="DM">Dominica 🇩🇲</option>
  <option value="DO">Dominican Republic 🇩🇴</option>
  <option value="EC">Ecuador 🇪🇨</option>
  <option value="EG">Egypt 🇪🇬</option>
  <option value="SV">El Salvador 🇸🇻</option>
  <option value="GQ">Equatorial Guinea 🇬🇶</option>
  <option value="ER">Eritrea 🇪🇷</option>
  <option value="EE">Estonia 🇪🇪</option>
  <option value="SZ">Eswatini 🇸🇿</option>
  <option value="ET">Ethiopia 🇪🇹</option>
  <option value="FK">Falkland Islands (Malvinas) 🇫🇰</option>
  <option value="FO">Faroe Islands 🇫🇴</option>
  <option value="FJ">Fiji 🇫🇯</option>
  <option value="FI">Finland 🇫🇮</option>
  <option value="FR">France 🇫🇷</option>
  <option value="GF">French Guiana 🇬🇫</option>
  <option value="PF">French Polynesia 🇵🇫</option>
  <option value="TF">French Southern Territories 🇹🇫</option>
  <option value="GA">Gabon 🇬🇦</option>
  <option value="GM">Gambia 🇬🇲</option>
  <option value="GE">Georgia 🇬🇪</option>
  <option value="DE">Germany 🇩🇪</option>
  <option value="GH">Ghana 🇬🇭</option>
  <option value="GI">Gibraltar 🇬🇮</option>
  <option value="GR">Greece 🇬🇷</option>
  <option value="GL">Greenland 🇬🇱</option>
  <option value="GD">Grenada 🇬🇩</option>
  <option value="GP">Guadeloupe 🇬🇵</option>
  <option value="GU">Guam 🇬🇺</option>
  <option value="GT">Guatemala 🇬🇹</option>
  <option value="GG">Guernsey 🇬🇬</option>
  <option value="GN">Guinea 🇬🇳</option>
  <option value="GW">Guinea-Bissau 🇬🇼</option>
  <option value="GY">Guyana 🇬🇾</option>
  <option value="HT">Haiti 🇭🇹</option>
  <option value="HM">Heard Island and McDonald Islands 🇭🇲</option>
  <option value="VA">Holy See (Vatican City State) 🇻🇦</option>
  <option value="HN">Honduras 🇭🇳</option>
  <option value="HK">Hong Kong 🇭🇰</option>
  <option value="HU">Hungary 🇭🇺</option>
  <option value="IS">Iceland 🇮🇸</option>
  <option value="IN">India 🇮🇳</option>
  <option value="ID">Indonesia 🇮🇩</option>
  <option value="IR">Iran, Islamic Republic of 🇮🇷</option>
  <option value="IQ">Iraq 🇮🇶</option>
  <option value="IE">Ireland 🇮🇪</option>
  <option value="IM">Isle of Man 🇮🇲</option>
  <option value="IL">Israel 🇮🇱</option>
  <option value="IT">Italy 🇮🇹</option>
  <option value="JM">Jamaica 🇯🇲</option>
  <option value="JP">Japan 🇯🇵</option>
  <option value="JE">Jersey 🇯🇪</option>
  <option value="JO">Jordan 🇯🇴</option>
  <option value="KZ">Kazakhstan 🇰🇿</option>
  <option value="KE">Kenya 🇰🇪</option>
  <option value="KI">Kiribati 🇰🇮</option>
  <option value="KP">Korea, Democratic People's Republic of 🇰🇵</option>
  <option value="KR">Korea, Republic of 🇰🇷</option>
  <option value="KW">Kuwait 🇰🇼</option>
  <option value="KG">Kyrgyzstan 🇰🇬</option>
  <option value="LA">Lao People's Democratic Republic 🇱🇦</option>
  <option value="LV">Latvia 🇱🇻</option>
  <option value="LB">Lebanon 🇱🇧</option>
  <option value="LS">Lesotho 🇱🇸</option>
  <option value="LR">Liberia 🇱🇷</option>
  <option value="LY">Libya 🇱🇾</option>
  <option value="LI">Liechtenstein 🇱🇮</option>
  <option value="LT">Lithuania 🇱🇹</option>
  <option value="LU">Luxembourg 🇱🇺</option>
  <option value="MO">Macao 🇲🇴</option>
  <option value="MG">Madagascar 🇲🇬</option>
  <option value="MW">Malawi 🇲🇼</option>
  <option value="MY">Malaysia 🇲🇾</option>
  <option value="MV">Maldives 🇲🇻</option>
  <option value="ML">Mali 🇲🇱</option>
  <option value="MT">Malta 🇲🇹</option>
  <option value="MH">Marshall Islands 🇲🇭</option>
  <option value="MQ">Martinique 🇲🇶</option>
  <option value="MR">Mauritania 🇲🇷</option>
  <option value="MU">Mauritius 🇲🇺</option>
  <option value="YT">Mayotte 🇾🇹</option>
  <option value="MX">Mexico 🇲🇽</option>
  <option value="FM">Micronesia, Federated States of 🇫🇲</option>
  <option value="MD">Moldova, Republic of 🇲🇩</option>
  <option value="MC">Monaco 🇲🇨</option>
  <option value="MN">Mongolia 🇲🇳</option>
  <option value="ME">Montenegro 🇲🇪</option>
  <option value="MS">Montserrat 🇲🇸</option>
  <option value="MA">Morocco 🇲🇦</option>
  <option value="MZ">Mozambique 🇲🇿</option>
  <option value="MM">Myanmar 🇲🇲</option>
  <option value="NA">Namibia 🇳🇦</option>
  <option value="NR">Nauru 🇳🇷</option>
  <option value="NP">Nepal 🇳🇵</option>
  <option value="NL">Netherlands, Kingdom of the 🇳🇱</option>
  <option value="AN">Netherlands Antilles 🇳🇱</option>
  <option value="NC">New Caledonia 🇳🇨</option>
  <option value="NZ">New Zealand 🇳🇿</option>
  <option value="NI">Nicaragua 🇳🇮</option>
  <option value="NE">Niger 🇳🇪</option>
  <option value="NG">Nigeria 🇳🇬</option>
  <option value="NU">Niue 🇳🇺</option>
  <option value="NF">Norfolk Island 🇳🇫</option>
  <option value="MP">Northern Mariana Islands 🇲🇵</option>
  <option value="MK">North Macedonia 🇲🇰</option>
  <option value="NO">Norway 🇳🇴</option>
  <option value="OM">Oman 🇴🇲</option>
  <option value="PK">Pakistan 🇵🇰</option>
  <option value="PW">Palau 🇵🇼</option>
  <option value="PS">Palestine, State of 🇵🇸</option>
  <option value="PA">Panama 🇵🇦</option>
  <option value="PG">Papua New Guinea 🇵🇬</option>
  <option value="PY">Paraguay 🇵🇾</option>
  <option value="PE">Peru 🇵🇪</option>
  <option value="PH">Philippines 🇵🇭</option>
  <option value="PN">Pitcairn 🇵🇳</option>
  <option value="PL">Poland 🇵🇱</option>
  <option value="PT">Portugal 🇵🇹</option>
  <option value="PR">Puerto Rico 🇵🇷</option>
  <option value="QA">Qatar 🇶🇦</option>
  <option value="RE">Réunion 🇷🇪</option>
  <option value="RO">Romania 🇷🇴</option>
  <option value="RU">Russian Federation 🇷🇺</option>
  <option value="RW">Rwanda 🇷🇼</option>
  <option value="BL">Saint Barthélemy 🇧🇱</option>
  <option value="SH">Saint Helena, Ascension and Tristan da Cunha 🇸🇭</option>
  <option value="KN">Saint Kitts and Nevis 🇰🇳</option>
  <option value="LC">Saint Lucia 🇱🇨</option>
  <option value="MF">Saint Martin 🇲🇫</option>
  <option value="PM">Saint Pierre and Miquelon 🇵🇲</option>
  <option value="VC">Saint Vincent and the Grenadines 🇻🇨</option>
  <option value="WS">Samoa 🇼🇸</option>
  <option value="SM">San Marino 🇸🇲</option>
  <option value="ST">Sao Tome and Principe 🇸🇹</option>
  <option value="SA">Saudi Arabia 🇸🇦</option>
  <option value="SN">Senegal 🇸🇳</option>
  <option value="RS">Serbia 🇷🇸</option>
  <option value="SC">Seychelles 🇸🇨</option>
  <option value="SL">Sierra Leone 🇸🇱</option>
  <option value="SG">Singapore 🇸🇬</option>
  <option value="SK">Slovakia 🇸🇰</option>
  <option value="SI">Slovenia 🇸🇮</option>
  <option value="SB">Solomon Islands 🇸🇧</option>
  <option value="SO">Somalia 🇸🇴</option>
  <option value="ZA">South Africa 🇿🇦</option>
  <option value="GS">South Georgia and the South Sandwich Islands 🇬🇸</option>
  <option value="ES">Spain 🇪🇸</option>
  <option value="LK">Sri Lanka 🇱🇰</option>
  <option value="SD">Sudan 🇸🇩</option>
  <option value="SR">Suriname 🇸🇷</option>
  <option value="SJ">Svalbard and Jan Mayen 🇸🇯</option>
  <option value="SE">Sweden 🇸🇪</option>
  <option value="CH">Switzerland 🇨🇭</option>
  <option value="SY">Syrian Arab Republic 🇸🇾</option>
  <option value="TW">Taiwan, Province of China 🇹🇼</option>
  <option value="TJ">Tajikistan 🇹🇯</option>
  <option value="TZ">Tanzania, United Republic of 🇹🇿</option>
  <option value="TH">Thailand 🇹🇭</option>
  <option value="TL">Timor-Leste 🇹🇱</option>
  <option value="TG">Togo 🇹🇬</option>
  <option value="TK">Tokelau 🇹🇰</option>
  <option value="TO">Tonga 🇹🇴</option>
  <option value="TT">Trinidad and Tobago 🇹🇹</option>
  <option value="TN">Tunisia 🇹🇳</option>
  <option value="TM">Turkmenistan 🇹🇲</option>
  <option value="TC">Turks and Caicos Islands 🇹🇨</option>
  <option value="TV">Tuvalu 🇹🇻</option>
  <option value="TR">Türkiye 🇹🇷</option>
  <option value="UG">Uganda 🇺🇬</option>
  <option value="UA">Ukraine 🇺🇦</option>
  <option value="AE">United Arab Emirates 🇦🇪</option>
  <option value="GB">United Kingdom 🇬🇧</option>
  <option value="US">United States of America 🇺🇸</option>
  <option value="UM">United States Minor Outlying Islands 🇺🇸</option>
  <option value="UY">Uruguay 🇺🇾</option>
  <option value="UZ">Uzbekistan 🇺🇿</option>
  <option value="VU">Vanuatu 🇻🇺</option>
  <option value="VE">Venezuela, Bolivarian Republic of 🇻🇪</option>
  <option value="VN">Viet Nam 🇻🇳</option>
  <option value="VG">Virgin Islands, British 🇻🇬</option>
  <option value="VI">Virgin Islands, U.S. 🇻🇮</option>
  <option value="WF">Wallis and Futuna 🇼🇫</option>
  <option value="EH">Western Sahara 🇪🇭</option>
  <option value="YE">Yemen 🇾🇪</option>
  <option value="ZM">Zambia 🇿🇲</option>
  <option value="ZW">Zimbabwe 🇿🇼</option>
</select>

*/
