import React, { Suspense } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import fetchData from '../fetchData';

const apiData = fetchData("https://jsonplaceholder.typicode.com/users");
function ApiFetchData() {
  const data = apiData.read();

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <ul className='card'>
          {data?.map((user) => (
            <li key={user.id}>{user.usuario}</li>
          ))}
        </ul>
      </Suspense>
    </div>
  )
}

export default ApiFetchData